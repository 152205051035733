import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';

import Sticky from 'Components/Sticky';
import { PROVIDER, SEEKER, USER_TYPE, CARGILL, PANTALOONS, CENTURY_PLY, DESMET, ARDEX_MIS_REPORT, GREENPLY_IDs, MITSUI, RAJ_PETRO, SAP_DEEPAK_PHENOLICS } from 'Constants/commonConstants';
import { GET_HOLISTICS } from 'Constants/actionConstants';
import { getCompanyType, getUserType, getUser } from 'Utils/storage';
import classnames from 'classnames';
import ActionLoader from 'Components/ActionLoader';
import { getEmbedCode } from 'Utils/common';

const reports = {};
const user = getUser();
const companyTextId = user.company_text_id;
const companyId = user.company_id;

if (SEEKER) {
    reports[SEEKER] = {
        'default': {}
    };
    let matchFound = false;
    if (PANTALOONS.includes(companyId)) {
        Object.assign(reports[SEEKER]['default'], {
            '114': ['Pantaloons Transporter Performance Reports', '40ab254beff3c3da9bba8d5b'],
            '115': ['Pantaloons Key Metrics Report - Only For Completed Trip', 'fed60ca58be6ce78e5b8ed89'],
            '117': ['Pantaloons TRACKING MIS', '8ce89cbaeb79c18ffdada59f'],
            '131': ['Pantaloons Key Information', 'cec0ffec00a73cdc9e28a75b'],
            // '6': ['Truck Arrival Report', '0dfe8ab029495c21643e13fe'],
            '7': ['Milestone MIS Inbound', 'b13204f7462f37dc99a7234b'],
            '8': ['Milestone MIS OutBound', 'c230291bc53fc0fa479977bc'],
            '9': ['Audit Done By', '48a701fe9aeca6f6c9c0f9bb'],
            '10': ['Store Movement', 'f9750926f50632c789a2b5d2']
        });
        matchFound = true;
    }

    if (['CENTURY_PLY'].includes(companyTextId)) {
        reports[SEEKER]['default']['1'] = [`Tracking MIS`, '355e95f2521ff6f3ab3bf631'],
        reports[SEEKER]['default']['7'] = ['InTransit Dashboard', '355e95f2521ff6f3ab3bf631'],
        matchFound = true;
    }

    if (['KRBL'].includes(companyTextId)) {
        reports[SEEKER]['default']['1'] = [`Tracking MIS`, getEmbedCode(companyTextId)],
        reports[SEEKER]['default']['2'] = ['Transporter Performance Report', 'c062b06b483b3c370597fd09'],
        reports[SEEKER]['default']['3'] = ['Key Metrics Report', 'b5ea5d5f0d6405f2d9dbdbfa'],
        reports[SEEKER]['default']['4'] = ['Key Information Dashboard', 'a03b5713939f01736226f708'],
        reports[SEEKER]['default']['5'] = ['Completed Trips', '6f613481542f656f994a309a'],
        // reports[SEEKER]['default']['6'] = ['Truck Arrival Report', '0dfe8ab029495c21643e13fe'],
        reports[SEEKER]['default']['7'] = ['Tracking MIS', '5613816c5c003f663dc9c1b1'],
        reports[SEEKER]['default']['8'] = ['Customised Tracking MIS', 'b108b306d91a35613d1478ef'],
        reports[SEEKER]['default']['178'] = ['KRBL VIMS - BILLING MIS', '157bc7d4b80d03edfe1c8ac3'],
        matchFound = true;
    }

    if (['BEAM'].includes(companyTextId)) {
        reports[SEEKER]['default']['1'] = [`Tracking MIS`, getEmbedCode(companyTextId)],
        reports[SEEKER]['default']['2'] = ['Transporter Performance Report', 'c062b06b483b3c370597fd09'],
        reports[SEEKER]['default']['3'] = ['Key Metrics Report', 'b5ea5d5f0d6405f2d9dbdbfa'],
        reports[SEEKER]['default']['4'] = ['Key Information Dashboard', 'a03b5713939f01736226f708'],
        reports[SEEKER]['default']['5'] = ['Completed Trips', '6f613481542f656f994a309a'],
        // reports[SEEKER]['default']['6'] = ['Truck Arrival Report', '0dfe8ab029495c21643e13fe'],
        reports[SEEKER]['default']['7'] = ['Tracking MIS', '01a4e4ee3e92594d6467bd29'],
        reports[SEEKER]['default']['182'] = ['BEAMS VIMS - BILLING MIS', '993ea43e4cef5a90753b9283'],
        matchFound = true;
    }
    if(ARDEX_MIS_REPORT.includes(companyId)){
        reports[SEEKER]['default']['1'] = [`Tracking MIS`, getEmbedCode(companyTextId)];
        reports[SEEKER]['default']['2'] = ['Transporter Performance Report', 'c062b06b483b3c370597fd09'];
        reports[SEEKER]['default']['3'] = ['Key Metrics Report', 'b5ea5d5f0d6405f2d9dbdbfa'];
        reports[SEEKER]['default']['4'] = ['Key Information Dashboard', 'a03b5713939f01736226f708'];
        reports[SEEKER]['default']['5'] = ['Completed Trips', '6f613481542f656f994a309a'];
        reports[SEEKER]['default']['174'] = ['ARDEX VIMS - BILLING MIS', '67087a8869141a93e55fedea'];
        matchFound = true;   
    }
    if(GREENPLY_IDs.includes(companyId)){
        reports[SEEKER]['default']['1'] = [`Tracking MIS`, getEmbedCode(companyTextId)];
        reports[SEEKER]['default']['2'] = ['Transporter Performance Report', 'c062b06b483b3c370597fd09'];
        reports[SEEKER]['default']['3'] = ['Key Metrics Report', 'b5ea5d5f0d6405f2d9dbdbfa'];
        reports[SEEKER]['default']['4'] = ['Key Information Dashboard', 'a03b5713939f01736226f708'];
        reports[SEEKER]['default']['5'] = ['Completed Trips', '6f613481542f656f994a309a'];
        reports[SEEKER]['default']['177'] = ['GREENPLY VIMS - BILLING MIS', '5b4d4d3ce2404d81bbd804fc'];
        matchFound = true;  
    }
    if(MITSUI.includes(companyId)){
        reports[SEEKER]['default']['1'] = [`MITSUI-TRACKING MIS`, '71cb941036a88c1fc168bc9d'];
        reports[SEEKER]['default']['2'] = ['Transporter Performance Report', 'c062b06b483b3c370597fd09'];
        reports[SEEKER]['default']['3'] = ['Key Metrics Report', 'b5ea5d5f0d6405f2d9dbdbfa'];
        reports[SEEKER]['default']['4'] = ['Key Information Dashboard', 'a03b5713939f01736226f708'];
        reports[SEEKER]['default']['5'] = ['Completed Trips', '6f613481542f656f994a309a'];
        reports[SEEKER]['default']['179'] = ['MITSUI VIMS - BILLING MIS', 'ff14bb1f3cd130dd54e05408'];
        matchFound = true;
    }
    if(RAJ_PETRO.includes(companyId)){
        reports[SEEKER]['default']['1'] = [`Tracking MIS`, getEmbedCode(companyTextId)];
        reports[SEEKER]['default']['2'] = ['Transporter Performance Report', 'c062b06b483b3c370597fd09'];
        reports[SEEKER]['default']['3'] = ['Key Metrics Report', 'b5ea5d5f0d6405f2d9dbdbfa'];
        reports[SEEKER]['default']['4'] = ['Key Information Dashboard', 'a03b5713939f01736226f708'];
        reports[SEEKER]['default']['5'] = ['Completed Trips', '6f613481542f656f994a309a'];
        reports[SEEKER]['default']['180'] = ['RAJ PETRO VIMS - BILLING MIS ', 'f718ab5dd4f9ec6fb170501d'];
        matchFound = true;
    }
    if(SAP_DEEPAK_PHENOLICS.includes(companyId)){
        reports[SEEKER]['default']['1'] = [`Tracking MIS`, getEmbedCode(companyTextId)];
        reports[SEEKER]['default']['2'] = ['Transporter Performance Report', 'c062b06b483b3c370597fd09'];
        reports[SEEKER]['default']['3'] = ['Key Metrics Report', 'b5ea5d5f0d6405f2d9dbdbfa'];
        reports[SEEKER]['default']['4'] = ['Key Information Dashboard', 'a03b5713939f01736226f708'];
        reports[SEEKER]['default']['5'] = ['Completed Trips', '6f613481542f656f994a309a']; 
        reports[SEEKER]['default']['176'] = ['DPL VIMS - BILLING MIS', '6e3231e6562b0e023e15b246']; 
        matchFound = true;
    }


    if (['BACARDI'].includes(companyTextId)) {
        Object.assign(reports[SEEKER]['default'], {
            '1': [`Tracking MIS`, getEmbedCode(companyTextId)],
            '2': ['Transporter Performance Report', 'c062b06b483b3c370597fd09'],
            '3': ['Key Metrics Report', 'b5ea5d5f0d6405f2d9dbdbfa'],
            '4': ['Key Information Dashboard', 'a03b5713939f01736226f708'],
            '5': ['Completed Trips', '6f613481542f656f994a309a'],
            // '6': ['Truck Arrival Report', '0dfe8ab029495c21643e13fe'],
            '84': ['Load Capacity', 'e090e6e8a8f553d8521d5f66'],
            '88': ['SAP Vs tEG Monthly report', '73c5e07d0da28e576afd863c'],
            '89': ['WEEKEND TRAFFIC DETAILS', '159ee2081734f993fc563d44'],
            '90': ['YTD Business Share', 'c162a5f489b0f3261453391e'],
            '183': ['BACARDI VIMS - BILLING MIS ','449392c9809f6c7aac6867e2'],
        });
        matchFound = true;
    }

    if(["JAIN_CARGO"].includes(companyTextId)){
        //************ THIS PROVIDER IS REGISTERED AS A SEEKER ********************/
        reports[SEEKER]['default']['1'] = [`Tracking MIS`, getEmbedCode(companyTextId)];
        reports[SEEKER]['default']['2'] = ['Transporter Performance Report', 'c062b06b483b3c370597fd09'];
        reports[SEEKER]['default']['3'] = ['Key Metrics Report', 'b5ea5d5f0d6405f2d9dbdbfa'];
        reports[SEEKER]['default']['4'] = ['Key Information Dashboard', 'a03b5713939f01736226f708'];
        reports[SEEKER]['default']['5'] = ['Completed Trips', '6f613481542f656f994a309a'];
        reports[SEEKER]['default']['184'] = ['JAIN CARGO VIMS - BILLING MIS', '8d4273a81e6a4257336e3364'];
        matchFound = true;
    }

    if (['TTBIL'].includes(companyTextId)) {
        Object.assign(reports[SEEKER]['default'], {
            // '6': ['Truck Arrival Report', '0dfe8ab029495c21643e13fe'],
            '127': ['TBIS - Key Information', '2de0dc9908122dbe3eb69722'],
            '128': ['TBIS - Key Metrics Report :: Only For Completed Trips', 'bc3fcf496ed524a398d80e62'],
            '129': ['TBIS - Transporter Performance Reports', '03ebcc87632fe60a562fdc2b'],
            '153': ['TBIS - TRACKING MIS', '29c05576c4df123084384b09'],
            '181': ['TBISPL VIMS - BILLING MIS', 'f331ca8068d3a01aa70f5944'],
        });
        matchFound = true;
    }
    
    if (['ADANI'].includes(companyTextId)) {
        reports[SEEKER]['default']['1'] = [`AWL Tracking MIS`, getEmbedCode(companyTextId)],
        matchFound = true;
    }

    if(['MUNDRA_SOLAR'].includes(companyTextId)){
        reports[SEEKER]['default']['4'] = ['Key Information Dashboard', 'a03b5713939f01736226f708'],
        reports[SEEKER]['default']['5'] = ['Completed Trips', '6f613481542f656f994a309a'],
        reports[SEEKER]['default']['160'] = ['MUNDRA SOLAR - VIMS MIS', '37c7c0ffe4a65ea411bbee60'],
        reports[SEEKER]['default']['173'] = ['MUNDRA SOLAR VIMS - BILLING MIS', '52ff398e2efce3ac09a021f0'],
        matchFound = true;
    }

    if(['CARGILL'].includes(companyTextId)){
        reports[SEEKER]['default']['1'] = [`Tracking MIS`, getEmbedCode(companyTextId)],
        reports[SEEKER]['default']['175'] = [`CARGIL VIMS - BILLING MIS`,'adda4176ef6b78d13ab8e3db'],
        matchFound = true; 
    }
    if(DESMET.includes(companyId)){
        reports[SEEKER]['default']['1'] = [`DESMET - TRACKING MIS`, '0eefbe3aea9870952d3bd41c'],
        matchFound = true;   
    }
    if(['LOREAL'].includes(companyTextId)){
        reports[SEEKER]['default']['1'] = [`L'OREAL - MILESTONE MIS`, '7cea3858e4b69feae5778069'],
        matchFound = true; 
    }

    if (companyId==='0') {
        //************ SUPER ADMIN BLOCK********************/
        reports[SEEKER]['default']['1'] = [`Tracking MIS (SA)`, '916b9bf926277300114b7d37'];
        reports[SEEKER]['default']['2'] = ['Transporter Performance Report', 'c062b06b483b3c370597fd09'];
        reports[SEEKER]['default']['3'] = ['Key Metrics Report', 'b5ea5d5f0d6405f2d9dbdbfa'];
        reports[SEEKER]['default']['4'] = ['Key Information Dashboard', 'a03b5713939f01736226f708'];
        reports[SEEKER]['default']['5'] = ['Completed Trips', '6f613481542f656f994a309a'];
        matchFound = true;
    }

    //**********************/ DEFAULT SEEKER  COMPANY SCENARIO /******************************** */
    if (!matchFound) {
        reports[SEEKER]['default']['1'] = [`Tracking MIS`, getEmbedCode(companyTextId)];
        reports[SEEKER]['default']['2'] = ['Transporter Performance Report', 'c062b06b483b3c370597fd09'];
        reports[SEEKER]['default']['3'] = ['Key Metrics Report', 'b5ea5d5f0d6405f2d9dbdbfa'];
        reports[SEEKER]['default']['4'] = ['Key Information Dashboard', 'a03b5713939f01736226f708'];
        reports[SEEKER]['default']['5'] = ['Completed Trips', '6f613481542f656f994a309a'];
    }
    //**************************************************************************************** */


    reports[SEEKER][USER_TYPE.ZONE] = {
        '1': ['Tracking MIS', '8a61f91bb451fd67e161619e'],
        '5': ['Completed Trips', 'a069e7d2b544679aaf90eec5']
    };
}

if (PROVIDER) {
    reports[PROVIDER] = {
        'default': {
            '1': ['Tracking MIS', '8405e55ffbafeb26eb198714'],
            '5': ['Completed Trips', '2af99c6569290c9cb570a30f']
        }
    };
}

const companyType =  getCompanyType();
const userType = getUserType();

class Holistics extends Component {
    constructor() {
        super();
        this.state = {
            activeTab : '1',
            embed_code : this.RenderLogicForGenericTrackingMISasFirstDefaultReport() && (reports[companyType][userType] || reports[companyType]['default'])['1'][1],
            token: ''
        }
    }
    RenderLogicForGenericTrackingMISasFirstDefaultReport = () => {
        if (PANTALOONS.includes(getUser().company_id)) {
            return false;
        }
        if (['TTBIL'].includes(getUser().company_text_id)) {
            return false;
        }
        if(['MUNDRA_SOLAR'].includes(companyTextId)){
            return false;
        }
        return true
    }


    toggle = (activeTab, embed_code) => {
        this.setState({ activeTab, embed_code, token: '' },() => {
            this.getToken();
        });
    }

    getToken = () => {
        const { dispatch } = this.props;
        dispatch({
            type: GET_HOLISTICS,
            data: {
                embed_code: this.state.embed_code
            }
        })
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.token !== this.state.token) {
            this.setState({ token : nextProps.token });
        }
    }


    render() {
        const { dataSubmitting } = this.props;
        const { token } = this.state;
        const activeTab = this.state.activeTab;
        const availableReports = reports[companyType][userType] || reports[companyType]['default'];
        console.log('availableReports:', availableReports)
        return (
            <Sticky activeLink={'mis'}>
                {!dataSubmitting && <ActionLoader fixed={true}></ActionLoader>}
                <Nav tabs className={'flex'} style={{'border': '1px solid lightgrey'}}>
                    {
                        Object.keys(availableReports).map((key, index) => {
                            const reportName = availableReports[key][0];
                            const embedCode = availableReports[key][1];
                            return (
                                <NavItem style={{'borderRight': '1px solid lightgrey', 'textAlign': 'center'}}>
                                    <NavLink
                                        className={classnames('fBolder', { active: activeTab === key })}
                                        onClick={() => { this.toggle(key, embedCode) }}
                                    >
                                    {reportName}
                                    </NavLink>
                                </NavItem>
                            )
                        })
                    }
                </Nav>
                <TabContent activeTab={activeTab}>
                    {
                        token && Object.keys(availableReports).map((key, index) => {
                            const embedCode = availableReports[key][1];
                            return (
                                <TabPane tabId={key}>
                                    <iframe
                                        src={`https://secure.holistics.io/embed/${embedCode}?_token=${token}`}
                                        style={{width: "100%", height: 600}}
                                        frameborder="0"
                                        title="Holistics"
                                        allowFullScreen
                                    >
                                    </iframe>
                                </TabPane>
                            )
                        })
                    }
                </TabContent>
            </Sticky>
        );
    }
    componentDidMount() {
        this.getToken();
    }
}

const mapStateToProps = ({trips}) => {
  return {
    token: trips.holistics.token,
    embedCode: trips.holistics.embedCode,
    dataSubmitting: trips.holistics.dataSubmitting
  }
};


export default connect(mapStateToProps)(Holistics);
