import React, { Component } from 'react';
import Moment from 'moment';

import Input from 'Components/Input';
import Dropdown from 'Components/Dropdown';

import Button from 'Components/Button';
import SearchDropdown from 'Components/SearchDropdown';
import ActionLoader from 'Components/ActionLoader';
import Checkbox from 'Components/Checkbox';
import Overlay from 'Components/Overlay';
import { ReactComponent as CloseIcon } from 'Components/TrackingSettings/icons/close-icon.svg';
import { SingleDatePicker } from 'react-dates';
import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker';
import { v4 as uuid4 } from 'uuid';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import { GoogleApiWrapper } from 'google-maps-react';
import { GOOGLE_API_KEY } from 'Constants/commonConstants';

import { scroll } from '../../utils/common';
import classNames from 'classnames';

const emptyGeoFenceLocation = {
    location: "",
}
const emptyGeoFenceLocationSec = {
    location: ""
}

class ChangeDriver extends Component {
    constructor(props) {
        super(props);
        const { trip } = props;
        this.state = {
            driverDetails: trip.driverDetails,
            trackingPreferences: trip.trackingPreferences,
            error: false,
            showPopUp: false,
            time: null,
            date: null,
            open: false,
            geofenceLocations: [{ ...emptyGeoFenceLocation, id: uuid4() }],
            geofenceLocationsSec: [{ ...emptyGeoFenceLocationSec, id: uuid4() }],
            locationLatLong: [],
            showChangeDriverPopUp: false,
            // newDriverName: "",
            newDriverFirstName: "",
            newDriverLastName: "",
            newDriverNumber: null,
            newDriverOperatorType: 0,
            newDriverOperatorName: "",
            newDriverOperatorFrom: {},
            newDriverOperatorTo: {},
            newDriverIsTracked: false,
            newgeofenceLocations: [{...emptyGeoFenceLocation, id: uuid4()}],
            newgeofenceLocationsSec: [{...emptyGeoFenceLocation, id: uuid4()}],
            isEnabledAddDriver: false,
            showAlert: false,
            isTrackedIndex: null
        }
    }
    componentDidMount() {
        const { trip } = this.props;

        // var index = 0;
        // for(let i=0; i<trip.driverDetails.length; i++) {
        //     if(trip.driverDetails[i].isTracked === true) {
        //         index = i;
        //         break;
        //     }
        // }

        this.setState({
            driverDetails: [...trip.driverDetails],
            trackingPreferences: [...trip.trackingPreferences],
            error: false,
            showPopUp: false,
            time: null,
            date: null,
            open: false,
            geofenceLocations: [{ ...emptyGeoFenceLocation, id: uuid4() }],
            geofenceLocationsSec: [{ ...emptyGeoFenceLocationSec, id: uuid4() }],
            locationLatLong: [],
            showChangeDriverPopUp: false,
            // newDriverName: "",
            newDriverFirstName: "",
            newDriverLastName: "",
            newDriverNumber: null,
            newDriverOperatorType: 0,
            newDriverOperatorName: "",
            newDriverOperatorFrom: {},
            newDriverOperatorTo: {},
            newDriverIsTracked: false,
            newgeofenceLocations: [{...emptyGeoFenceLocation, id: uuid4()}],
            newgeofenceLocationsSec: [{...emptyGeoFenceLocation, id: uuid4()}],
            isEnabledAddDriver: false,
            showAlert: false,
            isTrackedIndex: this.props.trackedIndex
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.numbersOperatorData && this.state.newDriverNumber === nextProps.numbersOperatorData.number) {
            this.setState({
                newDriverOperatorType: nextProps.numbersOperatorData.operator_type,
                newDriverOperatorName: nextProps.numbersOperatorData.operator_name
            })
        }
    }

    canChange = (tripType, changeOf) => {
        if (changeOf === 'operatorType') {
            return [2, 3, 4, 5, 7, 10].indexOf(tripType) > -1;
        } else if (changeOf === 'driver') {
            return [4, 5, 6, 7, 10].indexOf(tripType) > -1;
        }
        return false;
    }

    changeDriverPreference = (preferenceIndex) => {
        const {trip} = this.props;
        if(this.canChange(trip.type, 'driver')) {
            const { driverDetails } = this.state;
            trip.driverDetails.map((driver, index) => {
                if(index === +preferenceIndex) {
                    driver.isTracked = true;
                } else {
                    driver.isTracked = false
                }
            })
            this.setState({ driverDetails, isEnabledAddDriver: false });
        }
    }

    selectOperatorType = (index, operatorType) => {
        const { trip } = this.props;
        if (this.state.driverDetails[index].consentStatus !== 'allowed' || this.canChange(trip.type, 'operatorType')) {
            const driverDetails = [...this.state.driverDetails];
            driverDetails[index].operatorType = operatorType.id;
            driverDetails[index].operatorName = operatorType.name;
            this.setState({
                driverDetails,
                error: false
            });
        } else {
            return;
        }
    }

    onChangeDriver = () => {
        const { showChangeDriverPopUp } = this.state.showChangeDriverPopUp;
        if (showChangeDriverPopUp === true) {
            const driverDetails = [...this.state.driverDetails];
            driverDetails.splice(index, 1);
            this.setState({
                driverDetails
            })
        }
        this.setState({
            ...this.state,
            showChangeDriverPopUp: !this.state.showChangeDriverPopUp
        });
    }

    onDriverChange = (index, e) => {
        const { trip } = this.props;

        if ([2, 3].indexOf(trip.type) > -1) {
            const reg = new RegExp('^[0-9]+$');
            if (e.target.name == 'number' && e.target.value && !reg.test(e.target.value)) {
                return;
            }
            if (e.target.name == 'number' && e.target.value.length > 10) {
                return;
            }
            const driverDetails = [...this.state.driverDetails];
            driverDetails[index][e.target.name] = e.target.value;
            this.setState({
                driverDetails
            });
        } else {
            return;
        }
    }

    addDriverPreference = () => {
        const newState = {...this.state};
        const driverDetails = newState.driverDetails;
        for(var i in driverDetails) {
            driverDetails[i].isTracked = false;
        }
        this.setState({
            driverDetails,
            isEnabledAddDriver: true
        });
    }

    // validateFields = () => {
    //     const reg = new RegExp('^[0-9]{10}$');
    //     if(this.state.newDriverNumber && !reg.test(this.state.newDriverNumber)) {
    //         this.setState({
    //             error: true
    //         });
    //     }
    // }
    onNewDriverChange = (e) => {
        const reg = new RegExp('^[0-9]+$');
        if(e.target && e.target.name == "number" && e.target.value) {
            if (!reg.test(e.target.value) || e.target.value.length > 10) {
                return;
            }

            if (e.target.value.length == 10) {
                this.props.getNumbersOperator({number: e.target.value});
            }
        }

        this.setState({
            newDriverFirstName: e.target && e.target.name === "firstName" ? e.target.value : this.state.newDriverFirstName,
            newDriverLastName: e.target && e.target.name === "lastName" ? e.target.value : this.state.newDriverLastName,
            newDriverNumber: e.target && e.target.name === "number" ? e.target.value : this.state.newDriverNumber,
            newDriverOperatorType: e.id || this.state.newDriverOperatorType,
            newDriverOperatorName: e.name || this.state.newDriverOperatorName,
            newDriverOperatorFrom: this.state.newDriverOperatorFrom,
            newDriverOperatorTo: this.state.newDriverOperatorTo,
            newDriverIsTracked: this.state.isEnabledAddDriver || false,
        });
    }

    handleNewGeofenseLocationChange(value, id) {
        if (typeof value === "string") {
            const newLocs = this.state.newgeofenceLocations.map((loc) => {
                if (loc.id !== id) {
                    return loc;
                }
                return { ...loc, "location": value };
            });
            this.setState({ newgeofenceLocations: newLocs });
        } else {
            const newLocs = this.state.newgeofenceLocations.map((loc) => {
                if (loc.id !== id) {
                    return loc;
                }
                return { ...loc, "location": value };
            });
            this.setState({ newgeofenceLocations: newLocs });
        }
    }

    handleNewGeofenseLocationChangeSec(value, id) {
        if (typeof value === "string") {
            const newLocs = this.state.newgeofenceLocationsSec.map((loc) => {
                if (loc.id !== id) {
                    return loc;
                }
                return { ...loc, "location": value };
            });
            this.setState({ newgeofenceLocationsSec: newLocs });
        } else {
            const newLocs = this.state.newgeofenceLocationsSec.map((loc) => {
                if (loc.id !== id) {
                    return loc;
                }
                return { ...loc, "location": value };
            });
            this.setState({ newgeofenceLocationsSec: newLocs });
        }
    }

    onSaveClicked = async() => {
        const reg = new RegExp('^[0-9]{10}$');
        const {isEnabledAddDriver} = this.state;
        if(isEnabledAddDriver) {
            // await this.setGeoLocationCoordinates();
            const {locationLatLong} = this.state;
            const newDriverDetails = {
                name: this.state.newDriverName,
                first_name: this.state.newDriverFirstName,
                last_name: this.state.newDriverLastName,
                number: this.state.newDriverNumber,
                operator_type: this.state.newDriverOperatorType,
                operator_name: this.state.newDriverOperatorName,
                is_tracked: this.state.isEnabledAddDriver,
                from: locationLatLong[0] || {},
                to: locationLatLong[1] || {}
            }
            if(!reg.test(newDriverDetails.number) || (newDriverDetails.operator_type === "" || newDriverDetails.operator_name === "")) {
                this.state.error = true;
            }
            // if(Object.keys(newDriverDetails.from).length === 0 || Object.keys(newDriverDetails.to).length === 0) {
            //     this.state.error = true;
            // }
            else {
                this.state.error = false;
                this.setState({
                    showAlert: !this.state.showAlert
                });
            }
        }
        else {
            const {error} = this.state;
            if(!error) {
                this.setState({
                    showAlert: !this.state.showAlert
                });
            }
        }
    }

    hideOverlay = () => {
        this.setState({
            showAlert: !this.state.showAlert
        });
    }

    async setGeoLocationCoordinates() {
        try {
            const { newgeofenceLocations, newgeofenceLocationsSec } = this.state;
            const fromLocation = newgeofenceLocations[0].location || "";
            const toLocation = newgeofenceLocationsSec[0].location || "";

            const from = await geocodeByAddress(fromLocation);
            const fromCoord = await getLatLng(from[0]);

            const to = await geocodeByAddress(toLocation);
            const toCoord = await getLatLng(to[0]);
            // const locData = Promise.all([]);

            const locationLatLong = [];
            locationLatLong.push({ "latitude": fromCoord.lat, "longitude": fromCoord.lng, "place": fromLocation });
            locationLatLong.push({ "latitude": toCoord.lat, "longitude": toCoord.lng, "place" : toLocation });
            this.setState({
                locationLatLong
            });
        } catch (e) {
            console.log('error', e);
        }
    }

    onSave = async () => {
        const {driverDetails, isEnabledAddDriver} = this.state;
        const {trip} = this.props;
        let dataToBeSubmitted = {
            trip_id: trip.tripId,
            driver_details: (this.state.driverDetails || []).map((driverDetails) => {
                return {
                    name: driverDetails.name,
                    first_name: driverDetails.firstName,
                    last_name: driverDetails.lastName,
                    operator_type: driverDetails.operatorType || this.state.trackingPreferences[1].enabled ? driverDetails.operatorType : 0,
                    operator_name: driverDetails.operatorName || "",
                    number: driverDetails.number,
                    from: driverDetails.from || {},
                    to: driverDetails.to || {},
                    is_tracked: driverDetails.isTracked
                }
            })
        }
        if(isEnabledAddDriver) {
            await this.setGeoLocationCoordinates();
            const {locationLatLong} = this.state;
            const newDriver = {
                name: this.state.newDriverName,
                first_name: this.state.newDriverFirstName,
                last_name: this.state.newDriverLastName,
                number: this.state.newDriverNumber,
                operator_type: this.state.newDriverOperatorType,
                operator_name: this.state.newDriverOperatorName,
                is_tracked: this.state.isEnabledAddDriver,
                from: locationLatLong[0] || {},
                to: locationLatLong[1] || {},
            }
            dataToBeSubmitted.driver_details.push(newDriver);
        }
        this.setState({
            showAlert: !this.state.showAlert
        });
        this.props.onChangeDriver();
        this.props.submitTransporterDetails(dataToBeSubmitted);
    }

    render() {
        const {trip, operatorTypes} = this.props;
        return (

        <div className="overlay">
        <div className="overlay-container change-vehicle-popup modify-popup">
            <span className="overlay-close-button fs-20" onClick={this.props.onChangeDriver}>
                X
            </span>
            <div className="overlay-body change-driver-body-popup">
                <header className="fs-14 theme-color fBolder mb-20">
                    Driver Details
                </header>
                {trip.driverDetails.map((driver, index) => {
                    return (
                        <div className={`border-theme border-theme-tracked-${driver.isTracked} pl-15 pr-15 pt-15 pb-15 br-10 mb-10`}> {/* disabled-div-${index < this.state.isTrackedIndex} */}

                        <div className={`pr flex`}>
                            {/* <span
                                className="flex flex-middle curP ml-10 border-theme-light-dashed br-50p pl-5 pb-5 pr-5 pt-5 pa t--13 r--13"
                                onClick={this.removeDriver.bind(this, index)}
                            >

                            </span> */}
                            <Checkbox onCheck={this.changeDriverPreference.bind(this, index)} id={index} key={index} className="mr-10" checked={driver.isTracked} label={""} />
                            <div className="mb-20 mr-20">
                                <div className="fs-12 communication-color fBold mb-5">Driver First Name</div>
                                <Input value={driver.firstName} onChange={this.onDriverChange.bind(this, index)} name="firstName" />
                            </div>

                            <div className="mb-20 mr-20">
                                <div className="fs-12 communication-color fBold mb-5">Driver Last Name</div>
                                <Input value={driver.lastName} onChange={this.onDriverChange.bind(this, index)} name="lastName" />
                            </div>

                            <div className="mb-20 mr-20">
                                <div className="fs-12 communication-color fBold mb-5">Driver Number</div>
                                <Input type="number" value={driver.number} onChange={this.onDriverChange.bind(this, index)} name="number" />
                                {driver.number && driver.number.length < 10 && <span className={'fs-12 error-color'}>{'Invalid Phone Number'}</span>}
                            </div>
                            <div className="mb-20">
                                <div className="fs-12 communication-color fBold mb-20">
                                    Operator Type:
                                    {driver.number.length > 0 && this.state.trackingPreferences.filter((selectedTrackingPreference) => { return (selectedTrackingPreference.tracking_type === 2 && selectedTrackingPreference.enabled === true) })[0]
                                        && <span className={'error-color'}>*</span>}
                                </div>
                                <Dropdown value={operatorTypes.filter(operator => operator.id === driver.operatorType)[0] || {}}
                                          onSelect={this.selectOperatorType.bind(this, index)}
                                          items={trip.type === 6 ? [] : operatorTypes}
                                          label="Select Operator Type"
                                          selectedClassName={'col-1'}
                                />
                            </div>
                            <div className="mb-20 flex">
                                <div className="fs-12 communication-color fBold mb-5">
                                    <div className="mb-20">From Location</div>
                                    <SearchDropdown
                                        hideSearch={true}
                                        label={'From Location'}
                                        items={[]}
                                        value={(driver && driver.from && driver.from.place) ? driver.from.place : ''}
                                        onChange={() => false}
                                        selectedClassName={"route-detail-search"}
                                        containerClassName={'mt-10'}
                                    />
                                </div>

                                <div className="fs-12 communication-color ml-20 fBold mb-5">
                                    <div className="mb-20">To Location</div>
                                    <SearchDropdown
                                        hideSearch={true}
                                        label={'To Location'}
                                        items={[]}
                                        value={(driver && driver.to && driver.to.place) ? driver.to.place : ''}
                                        onChange={() => false}
                                        selectedClassName={"route-detail-search"}
                                        containerClassName={'mt-10'}
                                    />
                                </div>
                            </div>
                            {/* <div className="mb-20">

                                {
                                    this.state.geofenceLocations.map((loc, index) => {
                                        return <div className="mb-5 pl-20" key={index}>
                                            <span className={classNames("location-side-marker", {
                                                'last-in-geofence': index === this.state.geofenceLocations.length - 1
                                            })}>
                                                <span className="blue"> </span>
                                            </span>
                                            <span className={classNames("remove-btn-row", {
                                                'first-in-geofence': index === 0
                                            })}>
                                                <span className="location-side-marker right ">
                                                </span>

                                            </span>

                                            <PlacesAutocomplete
                                                key={index}
                                                value={loc.location}
                                                // onChange={(value) => { false }}
                                                // onSelect={(value) => { false }}
                                            >
                                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                    <div className="dropdown-container">
                                                        <div className="dropdown-container 2 ht-40 pr">
                                                            <input
                                                                {...getInputProps({
                                                                    placeholder: 'From location',
                                                                    className: 'selected-item row-100 pl-40 pr-16  route-detail-search wt-400',
                                                                })} />
                                                        </div>
                                                        <div className="row-100">
                                                            <ul className="dropdown-items flex flex-column" style={{ 'width': `600px` }}>
                                                                {
                                                                    suggestions.map((item, i) => {
                                                                        const className = "dropdown-item pl-16 ht-40";
                                                                        return (
                                                                            <li
                                                                                {...getSuggestionItemProps(item, {
                                                                                    className,
                                                                                })}
                                                                            >
                                                                                <abbr title={item.description}>
                                                                                    {item.description.substr(0, 75)}
                                                                                    {item.description.length > 75 ? '...' : ''}
                                                                                </abbr>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )}
                                            </PlacesAutocomplete>
                                        </div>
                                    })
                                }


                                {
                                    this.state.geofenceLocationsSec.map((loc, index) => {
                                        return <div className="mb-5 pl-20" key={index}>
                                            <span className={classNames("location-side-marker", {
                                                'last-in-geofence': index === this.state.geofenceLocationsSec.length - 1
                                            })}>
                                                <span className="blue"> </span>
                                            </span>
                                            <span className={classNames("remove-btn-row", {
                                                'first-in-geofence': index === 0
                                            })}>
                                                <span className="location-side-marker right ">
                                                </span>
                                            </span>

                                            <PlacesAutocomplete
                                                key={index}
                                                value={loc.location}
                                                // onChange={(value) => { false }}
                                                // onSelect={(value) => { false }}
                                            >
                                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                    <div className="dropdown-container">
                                                        <div className="dropdown-container 2 ht-40 pr">
                                                            <input
                                                                {...getInputProps({
                                                                    placeholder: 'To location',
                                                                    className: 'selected-item row-100 pl-40 pr-16  route-detail-search wt-400',
                                                                })} />
                                                        </div>
                                                        <div className="row-100">
                                                            <ul className="dropdown-items flex flex-column" style={{ 'width': `600px` }}>
                                                                {
                                                                    suggestions.map((item, i) => {
                                                                        const className = "dropdown-item pl-16 ht-40";
                                                                        return (
                                                                            <li
                                                                                {...getSuggestionItemProps(item, {
                                                                                    className,
                                                                                })}
                                                                            >
                                                                                <abbr title={item.description}>
                                                                                    {item.description.substr(0, 75)}
                                                                                    {item.description.length > 75 ? '...' : ''}
                                                                                </abbr>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )}
                                            </PlacesAutocomplete>
                                        </div>
                                    })
                                }
                            </div> */}

                        </div>
                        <div>{index === this.state.isTrackedIndex && <div className="fs-12 tracked-note">*NOTE: Currently active driver</div>}</div>
                        </div>
                    )
                })}
                <div className="add-driver-container pt-10 pl-10 pr-10 mb-5">
                    <header>
                        {(trip.type === 7 || trip.type === 6 || trip.type === 4) && <div className="ht-40 add-driver-button flex flex-wrap">
                            <Checkbox onCheck={this.addDriverPreference.bind(this)} className="mr-40 fs-12 curP" checked={this.state.isEnabledAddDriver} label={"Add new driver"} />
                            {/* <span onClick={this.addNewDriver} className="ml-5 mt-5 fs-12">Add new driver</span> */}
                        </div>
                        }
                    </header>
                    { this.state.isEnabledAddDriver &&
                    <div className="pr flex mb-10 pl-15 pr-15 pt-5 br-5">

                        <div className="mb-20 mr-20">
                            <div className="fs-12 communication-color fBold mb-5">Driver First Name</div>
                            <Input value={this.state.newDriverFirstName} onChange={this.onNewDriverChange.bind(this)} name="firstName" placeholder="Driver Name" />
                        </div>
                        <div className="mb-20 mr-20">
                            <div className="fs-12 communication-color fBold mb-5">Driver Last Name</div>
                            <Input value={this.state.newDriverLastName} onChange={this.onNewDriverChange.bind(this)} name="lastName" placeholder="Driver Name" />
                        </div>
                        <div className="mb-20 mr-20">
                            <div className="fs-12 communication-color fBold mb-5">Driver Number</div>
                            <Input type="number" value={this.state.newDriverNumber} onChange={this.onNewDriverChange.bind(this)} name="number" placeholder="Driver Number" pattern="[1-9]{1}[0-9]{9}"/>
                            {this.state.newDriverNumber && this.state.newDriverNumber.length < 10 && <span className={'fs-12 error-color'}>{'Invalid Driver Number'}</span>}
                        </div>
                        <div className="mb-20">
                            <div className="fs-12 communication-color mb-20 fBold mb-5">
                                Operator Type:
                        {this.state.trackingPreferences.filter((selectedTrackingPreference) => { return (selectedTrackingPreference.tracking_type === 2 && selectedTrackingPreference.enabled === true) })[0]
                                    && <span className={'error-color'}>*</span>}
                            </div>
                            <Dropdown value={operatorTypes.filter(operator => operator.id === this.state.newDriverOperatorType)[0] || {}} onSelect={this.onNewDriverChange.bind(this)} items={operatorTypes} label="Select Operator Type" selectedClassName={'col-1'} />
                            {this.state.error || this.state.newDriverOperatorType === 0 && <span className={'fs-12 error-color'}>{'Select Operator Type'}</span>}
                        </div>
                        {/*<div className="mb-20">*/}

                            {
                                this.state.newgeofenceLocations.map((loc, index) => {
                                    return <div className="mb-5 mr-10" key={index}>
                                        <div className="fs-12 communication-color fBold mb-20">From Location</div>
                                        <span className={classNames("location-side-marker", {
                                            'last-in-geofence': index === this.state.newgeofenceLocations.length - 1
                                        })}>
                                            <span className="blue"> </span>
                                        </span>
                                        <span className={classNames("remove-btn-row", {
                                            'first-in-geofence': index === 0
                                        })}>
                                            <span className="location-side-marker right ">
                                            </span>

                                        </span>

                                        <PlacesAutocomplete
                                            key={index}
                                            value={loc.location}
                                            onChange={(value) => { this.handleNewGeofenseLocationChange(value, loc.id) }}
                                            onSelect={(value) => { this.handleNewGeofenseLocationChange(value, loc.id) }}
                                        >
                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                <div className="dropdown-container">
                                                    <div className="dropdown-container 2 ht-40 pr">
                                                        <input
                                                            {...getInputProps({
                                                                placeholder: 'From location',
                                                                className: 'selected-item row-100 pl-40 pr-16  route-detail-search wt-200',
                                                            })} />
                                                    </div>
                                                    <div className="row-100">
                                                        <ul className="dropdown-items flex flex-column" style={{ 'width': `600px` }}>
                                                            {
                                                                suggestions.map((item, i) => {
                                                                    const className = "dropdown-item pl-16 ht-40";
                                                                    return (
                                                                        <li
                                                                            {...getSuggestionItemProps(item, {
                                                                                className,
                                                                            })}
                                                                        >
                                                                            <abbr title={item.description}>
                                                                                {item.description.substr(0, 75)}
                                                                                {item.description.length > 75 ? '...' : ''}
                                                                            </abbr>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                    </div>
                                })
                            }

                            {
                            this.state.newgeofenceLocationsSec.map((loc, index) => {
                                return <div className="mb-5" key={index}>
                                    <div className="fs-12 communication-color fBold mb-20">To Location</div>
                                    <span className={classNames("location-side-marker", {
                                        'last-in-geofence': index === this.state.newgeofenceLocationsSec.length - 1
                                    })}>
                                        <span className="blue"> </span>
                                    </span>
                                    <span className={classNames("remove-btn-row", {
                                        'first-in-geofence': index === 0
                                    })}>
                                        <span className="location-side-marker right ">
                                        </span>
                                    </span>

                                    <PlacesAutocomplete
                                        key={index}
                                        value={loc.location}
                                        onChange={(value) => { this.handleNewGeofenseLocationChangeSec(value, loc.id) }}
                                        onSelect={(value) => { this.handleNewGeofenseLocationChangeSec(value, loc.id) }}
                                    >
                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                            <div className="dropdown-container">
                                                <div className="dropdown-container 2 ht-40 pr">
                                                    <input
                                                        {...getInputProps({
                                                            placeholder: 'To location',
                                                            className: 'selected-item row-100 pl-40 pr-16  route-detail-search wt-200',
                                                        })} />
                                                </div>
                                                <div className="row-100">
                                                    <ul className="dropdown-items flex flex-column" style={{ 'width': `600px` }}>
                                                        {
                                                            suggestions.map((item, i) => {
                                                                const className = "dropdown-item pl-16 ht-40";
                                                                return (
                                                                    <li
                                                                        {...getSuggestionItemProps(item, {
                                                                            className,
                                                                        })}
                                                                    >
                                                                        <abbr title={item.description}>
                                                                            {item.description.substr(0, 75)}
                                                                            {item.description.length > 75 ? '...' : ''}
                                                                        </abbr>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        )}
                                    </PlacesAutocomplete>
                                </div>
                            })
                        }
                        {/*</div>*/}
                    </div>
                    }
                </div>
                <Button value='Save' onClick={this.onSaveClicked} className="save-change-driver"/>
                {
                    this.state.showAlert && <div className="overlay">
                    <div className="overlay-container">
                        <div className="overlay-body confirm">
                            <div className={'col-1 share'}>
                                <div className={'fBolder mt-10 mb-20 fBolder tCenter fs-16'}>Tracking will be switched to the selected driver. Do you want to continue?</div>

                                <div className={'mt-30 mr-40'}>
                                    <div className={'button-outlined button-yes share-button wt-100 tCenter fRight mr-40'} onClick={this.hideOverlay}>No</div>
                                    <div className={'button-outlined button-no share-button wt-100 tCenter fRight mr-20'} onClick={this.onSave}>Yes</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        </div>
    </div>
        )
    }
}

export default GoogleApiWrapper({
  apiKey: GOOGLE_API_KEY,
  libraries: ["places"]
})(ChangeDriver);
