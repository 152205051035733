import React, { Component } from 'react';
import { className } from 'classnames';
import { ReactComponent as UploadIcon } from '../../pages/MileStone/icons/upload.svg';
import { TOAST_ERROR } from 'Constants/actionConstants'

// var CloudmersiveVirusApiClient = require('cloudmersive-virus-api-client');
// var defaultClient = CloudmersiveVirusApiClient.ApiClient.instance;
// var Apikey = defaultClient.authentications['Apikey'];

// Apikey.apiKey = '624bcc25-345d-4005-84f7-1faf0d189b3f';

// var apiInstance = new CloudmersiveVirusApiClient.ScanApi();

export default class UploadButton extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    // onChange() {
    //     this.props.onChange(this.input.files[0]);
    // }

    onChange = (e)=> {
        if(this.props.multiple=='multiple'){
            var temp =[];
            for(var i=0;i<e.target.files.length;i++){
                temp[i] = e.target.files[i];
            }
            this.props.onChange(temp);
        }else{
            this.props.onChange(this.input.files[0]);
        }
    }

    render() {
        const { value, className, showFile, showIcon, multiple } = this.props;
        return (
            <div className="upload-button">
                <label className={"button upload " + (className || "")}>
                {multiple == 'multiple' ?
                    <input
                        className="upload-button-file"
                        ref={(ref) => { this.input = ref; }}
                        onChange={this.onChange}
                        type="file"
                        multiple
                    />
                    : 
                    <input
                        className="upload-button-file"
                        ref={(ref) => { this.input = ref; }}
                        onChange={this.onChange}
                        type="file"
                    />}
                    {value}
                    {showIcon && <UploadIcon className="wt-20 ht-22"/>}
                </label>
                {showFile && <span className="fs-12 fBolder">{this.input && this.input.files[0] && "File: " + this.input.files[0].name}</span>}
            </div>
        )
    }
}